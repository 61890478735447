import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MainService {
  public user: any;
  public empresa: any;
  public baseUrl: String = environment.BASE_URL;
  constructor(public http: HttpClient, private authService: AuthService) {
    console.log(this.baseUrl);
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", token);
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(`${this.baseUrl}${api}`, { headers: headers });
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
   getFile({ api }: { api: String }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", token);
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(`${this.baseUrl}${api}`, { headers: headers, responseType: 'blob' });
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (token && !headers.has("Authorization")) {
      headers = headers.append("Authorization", token);
    }
    return this.http.post(`${this.baseUrl}${api}`, data, {
      headers: headers,
    });
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  recover({ api, data }: { api: String; data: any }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (token && !headers.has("Authorization")) {
      headers = headers.append("Authorization", token);
    }
    const obj = { data };
    return this.http.post(`${this.baseUrl}${api}`, obj, {
      headers: headers,
    });
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (token && !headers.has("Authorization")) {
      headers = headers.append("Authorization", token);
    }
    return this.http.delete(`${this.baseUrl}${api}`, { headers: headers });
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put({ api, data }: { api: String; data: any }): Observable<any> {
    const token = this.authService.getToken();
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (token && !headers.has("Authorization")) {
      headers = headers.append("Authorization", token);
    }
    return this.http.put(`${this.baseUrl}${api}`, data, {
      headers: headers,
    });
  }
}
