import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService,
    private authService: AuthService,
    private navigationService: NavigationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError( (err) => {
        if (err) {
          if (err.status == 401) {
            this.alertService.handleError({error : 'Su sesión ha expirado, por favor ingrese de nuevo'});
            this.authService.logout();
            this.navigationService.routerNavigateTo({path: 'login'});
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      })
    );
  }
}
