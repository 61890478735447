import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { PublicModule } from "../public.module";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public baseUrl: String =environment.BASE_URL;
  constructor(public http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  public authenticateUser(user): Observable<any> {
    let headers = new HttpHeaders();
    return this.http
      .post(this.baseUrl + "users/authenticate", user, {
        headers: headers,
      })
  }

  public storeUserData(token, user) {
    localStorage.clear();
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public forgot(email): Observable<any> {
    const obj = { email };
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http
      .post(this.baseUrl + "users/forgot", obj, {
        headers: headers,
      })
  }

  public change(email, password, passwordNew):  Observable<any> {
    const obj = { email, password, passwordNew };
    let headers = new HttpHeaders();
    return this.http
      .post(this.baseUrl + "users/reset", obj, {
        headers: headers,
      })
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.removeItem("usuario");
    localStorage.removeItem("id_token");
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }
}
